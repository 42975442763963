<template>
  <v-card>
    <v-card-text>
      Progress notes
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" max-width="600px">
     
        <notes-form
        
          :note="vm"
          :organisationId="currentOrganisation.id"
          :caseId="currentCase.id"
          :goalId="currentGoal.id"
          :participantName="participantName"
          :participantFirstname="currentCase.participantDetails.firstname"
          :participantLastname="currentCase.participantDetails.lastname"
          :nDISNumber="nDISNumber"
        ></notes-form>
      </v-dialog>
      <v-data-table :items="notes" :headers="headers" class="elevation-1">
        <template slot="item" slot-scope="props">
          <tr>
            <td text-xs-left>{{props.item.duration}}</td>
            <td text-xs-left>{{props.item.quantity}}</td>
            <td text-xs-left>{{props.item.duration}}</td>
            <td text-xs-left>{{props.item.contactType}}</td>
            <td text-xs-left>{{props.item.startDateString + " " + props.item.startTime}}</td>
            <td text-xs-left>{{props.item.endDateString + " " + props.item.endTime}}</td>

          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

import * as notesService from "../../../../../services/progressNotesService";
Vue.use(VeeValidate);

export default {
  name: "notes-list",
  components: {
   
  },
  data: () => ({
    
    vm: {},
    
    valid: true,
  
    notes: [],
    caseId: "",
    goalId: "",
    participantName: "",
    nDISNumber: "",
    headers: [
      { text: "Duration", value: "duration" },
      { text: "Quantity", value: "quantity" },
      { text: "Quote", value: "quote" },
      { text: "Contact Type", value: "contactType" },
      { text: "Start", value: "start" },
      { text: "End", value: "end" }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    if (this.currentCase !== null) {
      this.participantName =
        this.currentCase.participantDetails.firstname +
        " " +
        this.currentCase.participantDetails.lastname;
      this.nDISNumber = this.currentCase.participantDetails.nDISNumber;
    }

    if (this.currentGoal != null) {
      this.init();
    }
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentGoal() {
      return this.$store.getters.currentGoal;
    }
  },
  methods: {
    init() {
      notesService
        .list(
          this.currentOrganisation.id,
          this.currentCase.id,
          this.currentGoal.id
        )
        .then(result => {
          let notes = [];
          result.docs.map(doc => {
            const note = doc.data();
            note.id = doc.id;
            notes.push(note);
          });
          this.notes = notes;
        });
    },
    addNote() {
      if (this.currentCase.notes == undefined) {
        this.currentCase.notes = [];
      }
      this.currentCase.notes.push(this.vm);
      this.dialog = false;
    },
    noteAdded(data) {
      if (this.editedIndex > -1) {
        Object.assign(this.notes[this.editedIndex], data);
      } else {
        this.notes.push(data);
      }
      this.noteClosed();
      console.log(data);
    },
    noteClosed() {
      this.dialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.contacts = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    editItem(item) {
      this.editedIndex = this.notes.indexOf(item);
      this.caseId = item.caseId;
      this.goalId = item.goalId;
      this.participantName = item.participantName;
      this.nDISNumber = item.nDISNumber;

      this.vm = Object.assign({}, item);
      this.dialog = true;
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

